import React, { useState, useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "./Policy.css";

export const Policy = (props) => {
  const data = props.data;
  const pageHeading = props.heading;
  const navbarHeight = 88;
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [expandedSections, setExpandedSections] = useState(
    Array(data.length).fill(false)
  );

  useEffect(() => {
    // Check the screen width and update isSmallScreen accordingly
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 992);
    };

    // Initial check and add event listener
    handleResize();
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSectionClick = (index) => {
    const sectionElement = document.getElementById(`section-${index}`);
    if (sectionElement) {
      // Calculate the target scroll position
      const targetScrollPosition =
        sectionElement.getBoundingClientRect().top +
        window.scrollY -
        navbarHeight -
        (isSmallScreen ? 128 : 40); // Adjust the offset for small screens

      // Smoothly scroll to the target position
      window.scrollTo({
        top: targetScrollPosition,
        behavior: "smooth",
      });
    }
  };
  const onExpandClickHandler = (index) => {
    setExpandedSections((prev) => {
      const newExpandedSections = [...prev];
      newExpandedSections[index] = !newExpandedSections[index];
      return newExpandedSections;
    });
  };
  return (
    <div className="container container-policy">
      <div className="row">
        <div
          className={`col-md-${isSmallScreen ? "12" : "3"
            } col-sm-12 policy-left`}
        >
          {isSmallScreen && <h3 id="policy-heading">{pageHeading}</h3>}

          <ol className="policy-ordered-list">
            {data.map((section, index) =>
              section.subHeadings ? (
                <li key={index}>
                  <a
                    onClick={() => onExpandClickHandler(index)}
                    className="policy-link"
                    style={{ cursor: "pointer" }}
                  >
                    <span className="sidebar-heading">
                      {index + 1}.{" "}
                      {section.heading ===
                        "Intellectual Property/Copyrights/Trademark"
                        ? section.heading.split("/")[0]
                        : section.heading}
                    </span>
                    {expandedSections[index] ? (
                      <span className=" sidebar-arrow" style={{ height: "20px"}}>
                        <ExpandMoreIcon />
                      </span>
                    ) : (
                      <span className="sidebar-arrow" style={{ height: "20px"}}>
                        <ChevronRightIcon />
                      </span>
                    )}
                  </a>
                  {expandedSections[index] && (
                    <div className="policy-link-container">
                      {section.subHeadings.map((sec, ind) => (
                        <p key={ind}>
                          <a
                            className="policy-link"
                            onClick={() =>
                              handleSectionClick(`${index + 1}.${ind + 1}`)
                            }
                          >
                            {index + 1}.{ind + 1}. {sec.heading}
                          </a>
                        </p>
                      ))}
                    </div>
                  )}
                </li>
              ) : (
                <li key={index}>
                  <a
                    className="policy-link"
                    onClick={() => handleSectionClick(index + 1)}
                  >
                    {index + 1}. {section.heading}
                  </a>
                </li>
              )
            )}
          </ol>
        </div>
        <div
          className={`col-md-${isSmallScreen ? "12" : "9"
            } col-sm-12 policy-right main-content`}
        // style={{ padding: "7px 0 0 22px" }}
        >
          {!isSmallScreen && <h3 id="policy-heading">{pageHeading}</h3>}
          {data.map((section, index) => (
            <div
              key={index}
              className="policy-section"
              id={`section-${index + 1}`}
            >
              {section.subHeadings ? (
                <>
                  <h5>
                    {index + 1}. {section.heading}
                  </h5>
                  {section.subHeadings.map((sec, ind) => (
                    <div
                      className="policy-subHeadings"
                      id={`section-${index + 1}.${ind + 1}`}
                      key={ind}
                    >
                      <h6>
                        {index + 1}.{ind + 1}. {sec.heading}
                      </h6>
                      {sec.description.split("[[").length > 1 ?
                        <p>
                          {
                            sec.description.split("[[")[0]
                          }
                          {
                            <a href="/privacy" style={{ color: "red" }} target="_blank">{sec.description.split("[[")[1]}</a>
                          }
                          {
                            sec.description.split("[[")[2]
                          }

                        </p>

                        : <p>
                          {sec.description.split("[[")[0]}
                        </p>
                      }
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <h5>
                    {index + 1}. {section.heading}
                  </h5>
                  <p>{section.description}</p>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div >
  );
};
