
export const YELLOW = {
  palette: {
    type: 'light',
    primary: {
      main: '#FFBE2E',
    },
    secondary: {
      main: '#FFBE2E'
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#000000'
    }
  },
  typography: {
    fontFamily: [
      'Plus Jakarta Sans',
      'sans-serif'
    ].join(','),
  }
}

export const RED = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      xmd: 900,
      md: 1050,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    type: 'light',
    primary: {
      main: '#ffffff',
      contrastText: "#000000"
    },
    secondary: {
      main: '#000000',
      contrastText: "#ffffff"
    },
    tertiary: {
      main: '#ff403a',
      contrastText: "#ffffff"
    },
    contact: {
      main: '#ff403a',
      contrastText: "#ffffff"
    },
    auth:{
      main: '#333333',
      contrastText: "#ffffff"
    },
    text: {
      primary: '#000000',
      secondary: '#ffffff',
      tertiary: '#ffffff'
    }
  },
  typography: {
    fontFamily: [
      'Plus Jakarta Sans',
      'sans-serif'
    ].join(','),
    button: {
      textTransform: 'none',
      fontSize: '13px'
    }
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            fontSize: '13px'
          },
          color: '#374151',
          backgroundColor: 'rgb(249, 249, 249)',
          fontSize: '14px',
        }
      }
    },
  }
}

export default RED