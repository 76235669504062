import React from 'react';
import './loader.css';
import AnimatedLogo from './animatedLogo';

const Loader = ({children, lightBackground=false, disableBackdrop }) => {
	return (
		<div className={`loader-backdrop ${disableBackdrop ? "" : lightBackground ? "light-bg" : "dark-bg"}`}>
			<div className="loader-spinner">
				<AnimatedLogo />
				{children}
			</div>
		</div>
	);
}

export default Loader;