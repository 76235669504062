import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Theme from "./constants/Themes";

import TermsOfService from "./constants/TermsOfService";
import PrivacyPolicy from "./constants/PrivacyPolicy";
import { Policy } from "./views/Policy/Policy";

import { IdentityProvider, RouteGuard } from "@ourodemi/identity-react";
import { ToastProvider } from "./contexts/ToastContext";
import { APIProvider } from "./contexts/APIContext";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { GOOGLE_RECAPTCHA__KEY } from "./constants/Keys";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { Suspense, lazy } from "react";
import { Loader } from "./components/misc";

const AboutUs = lazy(() => import("./views/AboutUs"));
const Tracking = lazy(() => import("./views/Tracking/Tracking"));
const HelpCenter = lazy(() => import("./views/HelpCenter/HelpCenter"));
const VolumetricCalculator = lazy(() => import("./views/VolumetricCalculator/VolumetricCalculator"));
const Home = lazy(() => import("./views/Home/Home"));
const OrderProcessing = lazy(() => import("./views/OrderProcess/OrderProcessing"));
const MainApp = lazy(() => import("./views/MainApp"));
const Dashboard = lazy(() => import("./views/Dashboard/Dashboard"));
const DashboardOutlet = lazy(() => import("./views/DashboardOutlet/DashboardOutlet"));
const Profile = lazy(() => import("./views/Profile/Profile"));
const Addresses = lazy(() => import("./views/Addresses/Addresses"));
const Billing = lazy(() => import("./views/Billing/Billing"));
const NearbyCouriers = lazy(() => import("./components/sections"));
const Services = lazy(() => import('./views/Services/Services'));
const ComingSoon = lazy(() => import('./views/ComingSoon/ComingSoon'));
const Governance = lazy(() => import("./views/Governance/Governance"));
const Feedback = lazy(() => import("./views/Feedback/Feedback"));
const BookingConfirmation = lazy(() => import("./views/BookingConfirmation"));
const CourierLocation = lazy(() => import("./views/CourierLocation/CourierLocation"));
const PaymentConfirmation = lazy(() => import("./views/PaymentConfirmation"));


const baseTheme = createTheme(Theme);

gsap.registerPlugin(useGSAP, ScrollTrigger);
gsap.defaults({
  ease: "power1.inOut",
  duration: 0.5,
  opacity: 0,
  transformStyle: "preserve-3d"
});

const SANDBOX_API = "https://sandbox.shipfro.com/v1";
const LIVE_API = "https://api.shipfro.com/v1";
const LOCAL_API = "http://localhost:8080/v1";

function resolveAPI(){
  switch(process.env.REACT_APP_ENV){
    case "production":
        return LIVE_API;
    case "development":
      return LOCAL_API;
    case "sandbox":
      return SANDBOX_API;
    default:
      return SANDBOX_API;
  }
}

function App() {
  return (
    <IdentityProvider
      url="shipfro-auth-ilv9k.ourodemi.com"
      apiConfig={{
        url: resolveAPI(),
        headers: { access_token: "Authorization" },
        defaultHeaders: {
          Accept: "application/json",
        },
      }}
      defaultRouteState={{ auth: false }}
      defaultRoute="/"
      defaultAuthenticatedRoute="/dashboard"
    >
      <GoogleReCaptchaProvider
        reCaptchaKey={GOOGLE_RECAPTCHA__KEY}
        // language=""
        useRecaptchaNet={false}
        useEnterprise={false}
        scriptProps={{
          async: false, // optional, default to false,
          defer: false, // optional, default to false
          appendTo: 'head', // optional, default to "head", can be "head" or "body",
          nonce: undefined // optional, default undefined
        }}
      >
        <APIProvider>
          <ThemeProvider theme={baseTheme}>
            <ToastProvider>
              <Suspense fallback={<Loader disableBackdrop />}>
                <BrowserRouter>
                  <Routes>
                    <Route path="/" element={<MainApp />}>
                      <Route index element={<Home />} />
                      <Route path="about" element={<AboutUs />} />
                      <Route path="services" element={<Services />} />
                      <Route path="help" element={<HelpCenter />} />
                      <Route path="tracking" element={<Tracking />} />
                      <Route path="calculator" element={<VolumetricCalculator />} />
                      <Route path="booking" element={<OrderProcessing />} />
                      <Route path="coming-soon" element={<ComingSoon />} />
                      <Route path="feedback" element={<Feedback />} />
                      <Route
                        path="terms"
                        element={<Policy data={TermsOfService} heading="Terms of Service" />}
                      />
                      <Route
                        path="privacy"
                        element={<Policy data={PrivacyPolicy} heading="Privacy Policy" />}
                      />
                      <Route path="nearbyCouriers" element={<NearbyCouriers />} />
                      <Route path="couriers/locations" element={<CourierLocation />} />
                    </Route>
                    <Route path="payment-confirmation" element={<PaymentConfirmation />} />
                    <Route path="booking-confirmation/:id" element={<BookingConfirmation />} />

                    <Route
                      path="/dashboard"
                      element={
                        <RouteGuard>
                          <Dashboard />
                        </RouteGuard>
                      }
                    >
                      <Route index element={<DashboardOutlet />} />
                      <Route path="deliveries" element={<DashboardOutlet />} />
                      <Route path="profile" element={<Profile />} />
                      <Route path="addresses" element={<Addresses />} />
                      <Route path="billing" element={<Billing />} />
                    </Route>
                    <Route
                      path="/governance"
                      element={<Governance />}
                    ></Route>
                  </Routes>
                </BrowserRouter>
              </Suspense>
            </ToastProvider>
          </ThemeProvider>
        </APIProvider>
      </GoogleReCaptchaProvider>
    </IdentityProvider>
  );
}

export default App;
