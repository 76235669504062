import styles from "./StepLabel.module.css"

const StepLabel = ({
    step = "01",
    label = '',
}) => {

    return (
        <div className={styles.step_input}>
            <div className={styles.avatar}>{step}</div>
            <div className={styles.label}>{label}</div>
        </div>
    )
}

export default StepLabel;