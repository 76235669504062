import './animatedLogo.css'

const AnimatedLogo = () => {
    return (
        <svg width="100px" height="100px" viewBox="0 0 38 35" xmlns="http://www.w3.org/2000/svg">
            <path className="path" d="M17.019 1.93c-4.485 0-8.485 3.013-9.522 7.376a9.598 9.598 0 0 0 2.73 9.197 9.533 9.533 0 0 0 4.281 2.33c1 .252 2.04.313 3.07.252l2.04-.122-1.797-5.302h1.167l3.63 5.122 2.854-.169a5.899 5.899 0 0 1 3.755 1.07l.111.078a.52.52 0 0 1 .22.453.517.517 0 0 1-.22.452l-.111.08a5.903 5.903 0 0 1-3.755 1.069l-2.854-.17-3.63 5.123H17.82l1.796-5.303-6.07-.362-1.478 1.392h-.985a9.59 9.59 0 0 0-9.443 7.927H24.68c4.476 0 8.482-2.996 9.533-7.345a9.595 9.595 0 0 0-2.805-9.303c-1.81-1.685-4.23-2.561-6.704-2.561h-.522v-.027c0-.529-.43-.96-.96-.96s-.96.431-.96.96v.027h-5.657v-.027c0-.529-.432-.96-.96-.96-.53 0-.961.431-.961.96v.027h-.926a.103.103 0 0 1-.103-.103v-.448c0-.055.045-.103.103-.103h.11v-.336a.57.57 0 0 1 .096-.312l.199-.296c.066-.1.158-.18.267-.236l.693-.352 2.664-2.08a.965.965 0 0 1 .606-.206H31.301a4.513 4.513 0 0 0 4.445-3.733l.543-3.08H17.022Z" />
        </svg>
    )
}

export default AnimatedLogo;