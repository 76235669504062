const PrivacyPolicy = [
  {
    heading: "Application of Pakistani Data Protection Laws",
    description:
      "Shipfro operates in full compliance with data privacy and protection laws prevailing in Pakistan. With a profound commitment to data privacy, Shipfro prioritizes the security and confidentiality of user information. A pivotal aspect of this commitment is the unequivocal declaration that customer data, especially that of the end-users, is not shared unless imperative for the completion of contracted transactions.",
  },
  {
    heading: "Scope of This Privacy Policy",
    description:
      "This Policy is expressly applicable to Shipfro's services and operational facets within the territorial boundaries of Pakistan. Acting as the custodian of personal information for its direct-to-consumer services, Shipfro, in certain instances, may assume the role of a custodian or processor of personal data for business clientele.",
  },

  {
    heading: "Information Collection and Usage",
    subHeadings: [
      {
        heading: "Types of Information We Collect",
        description:
          "Shipfro engages in the collection of various types of personal information indispensable for the provision of its services. This includes, but is not limited to, names, addresses, phone numbers, and other pertinent data crucial for postal and shipping services.",
      },
      {
        heading: "How We Use Your Information",
        description:
          "The collected user data serves multiple purposes, including service delivery, communication facilitation, customer support, and marketing endeavors. Importantly, all data utilization aligns with the stringent parameters set by Pakistani data privacy laws.",
      },
    ],
  },
  {
    heading: "Sharing of Your Personal Information",
    description:
      "Transparency is the hallmark of Shipfro's information-sharing practices. The organization categorically refrains from partaking in the sale of personal information. In specific instances, information sharing may occur with local service providers in Pakistan actively contributing to the facilitation of services and overall business operations.",
  },
  {
    heading: "Security Measures",
    description:
      "Ensuring the security of personal information stands as a paramount objective for Shipfro. The organization diligently adheres to industry-standard security protocols to safeguard data during transmission and storage. These measures are meticulously designed to guarantee the confidentiality and integrity of user information.",
  },
  {
    heading: "Data Retention",
    description:
      "Shipfro undertakes the responsibility of retaining user data for a duration deemed necessary for the fulfillment of services, compliance with legal obligations, and the resolution of disputes. Users are endowed with the right to access, correct, update, or request the deletion of their information, all within the contours of Pakistani data protection laws.",
  },
  {
    heading: "Contact Information",
    description:
      "For any inquiries, clarifications, or comments pertaining to this Policy, personal information, or privacy rights, users are encouraged to initiate contact with Shipfro at support@shipfro.com.",
  },
  {
    heading: "Policy Updates",
    description:
      "This Policy may undergo periodic updates to align with evolving circumstances. Material changes will be communicated to users to ensure transparency. Users who may disagree with any alterations are advised to discontinue using Shipfro's services.",
  },
];

export default PrivacyPolicy;
