const TermsOfService = [
  {
    heading: "Introduction",
    description:
      "Welcome to Shipfro! We are Shipfro, a European-funded startup based in Pakistan. As a digital platform for courier aggregation, we partner with top courier companies, reshaping the landscape with our innovative approach. These Terms of Service constitute a legally binding agreement between you and Shipfro. By using Shipfro's platform and services, you acknowledge that you have read, understood, and agreed to these Terms of Service. If you do not agree with any part of these terms, please refrain from using Shipfro's services.",
  },
  {
    heading: "User Agreement",
    description:
      "By using Shipfro's platform and services, you agree to abide by the following Terms of Service. Please read them carefully",
    subHeadings: [
      {
        heading: "Description of Services",
        description:
          "Shipfro provides a platform for courier aggregation. We partner with courier companies to offer courier and shipping services to users. Shipfro allows users to generate shipping labels, track shipments, and access various courier services.",
      },
      {
        heading: "User Accounts",
        description:
          "To access Shipfro's services, you must create a user account. You agree to provide accurate and complete information during the registration process. It is your responsibility to maintain the confidentiality of your account credentials and notify Shipfro immediately of any unauthorized use of your account.",
      },
      {
        heading: "Eligibility",
        description:
          "You must be at least 18 years old to use Shipfro's services. By using Shipfro, you represent and warrant that you are of legal age to form a binding contract and meet all eligibility requirements..",
      },
      {
        heading: "Acceptable Use",
        description:
          "You agree not to use Shipfro's platform and services for any unlawful, fraudulent, or malicious purposes. You must comply with all applicable laws and regulations while using Shipfro. Shipfro reserves the right to terminate your account and report any illegal activities to the authorities.",
      },
      {
        heading: "Privacy Policy",
        description:
          "Shipfro values your privacy. Any information you provide to us will be used in accordance with our privacy policy, which can be found at our [[Privacy Policy[[. Shipfro does not sell, rent, or otherwise disclose your confidential information to a third party without your consent or as permitted by the Privacy Policy.",
      },
    ],
  },

  {
    heading: "Payments",
    subHeadings: [
      {
        heading: "Pricing and Fees",
        description:
          "Shipfro's services may involve fees, including shipping charges and taxes. You agree to pay all applicable fees in connection with your use of Shipfro's services. Shipfro reserves the right to change its pricing and fee structure at any time. All pricing and fees are non-refundable.",
      },
      {
        heading: "Payment Processing",
        description:
          "Shipfro uses third-party payment processing services to facilitate payments. Shipfro is not responsible for any errors made by these third-party services.",
      },
    ],
  },
  {
    heading: "Shipfro's Role and Limitations",
    subHeadings: [
      {
        heading: "Shipfro's Role",
        description:
          "Shipfro is a platform that connects users with courier companies. We facilitate the generation of shipping labels and tracking of shipments. Shipfro is not responsible for the delivery or transportation of your shipments. We do not inspect your goods, nor do we take responsibility for the business decisions that you make and implement through our services.",
      },
      {
        heading: "Third-Party Liability",
        description:
          "By accepting these Terms of Service, you acknowledge and agree that Shipfro does not assume any liability for acts, errors, or omissions of any third-party service providers with whom you or any of your affiliates may contract. This includes courier companies and customs authorities.",
      },
      {
        heading: "Accuracy of Information",
        description:
          "You are responsible for providing complete and accurate information in respect of your shipments. You shall be responsible for any errors, damage, expenses, or consequences resulting from incorrect or incomplete information provided to us. Shipfro shall not be responsible for losses or damages if any item contained in the shipment is wrongfully declared. We are also not liable for the accuracy and truthfulness of the information you provide.",
      },
      {
        heading: "Customs",
        description:
          "Shipfro shall not be liable for any loss, delay, or damage of items shipped while in the hands of local customs. We are not responsible in the event that local customs confiscates, holds, or denies the release of the shipment to the receiver.",
      },
      {
        heading: "Service Provided by Courier",
        description:
          "Shipfro is a technology platform that connects shippers and couriers. We are not liable for any services or information provided by any courier. This includes any damage, destruction, loss, late delivery of shipments, failures in handling shipments to couriers, failed or late shipment collection by couriers, or any issues with tracking updates.",
      },
      {
        heading: "User Courier Account",
        description:
          "Users are responsible for providing accurate and truthful information for the User Courier Account they wish to use with Shipfro. Users acknowledge and certify that they are the rightful owner of the User Courier Account. Shipfro is not liable nor responsible for failures in connecting a User Courier Account, inaccuracies in tracking information, discrepancies between any shipping costs or fees shown by Shipfro and billed by the courier, or any other fees billed by the courier to the owner of the User Courier Account.",
      },
    ],
  },
  {
    heading: "Prohibited Items",
    description: `By using Shipfro's platform and services, you acknowledge and agree that you will not use our services to ship the following prohibited items:
Currency, Stamps, Works of Art, Jewelry, Precious Metals, Precious Stones, Bullion, Firearms, Explosives, Antiques, Plants, Animals, Foodstuff, Liquor, Tobacco, Perishable Items, Negotiable Instruments in Bearer Form, Lewd, Obscene, or Pornographic Material, Industrial Carbons and Diamonds, Items/Articles Restricted by IATA (International Air Transport Association) or ICAO (International Civil Aviation Organization), Hazardous or Combustible Materials, Property the Carriage of Which Is Prohibited by Any Law, Regulation, or Statute of Any Provincial or Federal Government of Pakistan
Please note that Leopards and TCS courier partners do not accept certain items, and the responsibility of complying with these restrictions lies with the shipper. We are not responsible for any loss or damage to articles unless they are properly insured.
`,
  },
  {
    heading: "Intellectual Property/Copyrights/Trademark",
    subHeadings: [
      {
        heading: "Shipfro's Intellectual Property",
        description:
          "All content and materials on the Shipfro platform, including but not limited to text, graphics, logos, icons, images, audio and video clips, digital downloads, data compilations, and software, are the property of Shipfro or its content suppliers and are protected by international copyright laws. Shipfro retains all rights, including copyright, in the platform and its content.",
      },
      {
        heading: "Trademarks",
        description:
          "The Shipfro name, logo, and any other trademarks or service marks used on the Shipfro platform are the property of Shipfro and may not be used in connection with any product or service that is not Shipfro's, in any manner that is likely to cause confusion among users, or in any manner that disparages or discredits Shipfro. All other trademarks not owned by Shipfro that appear on this platform are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by Shipfro.",
      },
      {
        heading: "Limited License",
        description:
          "Subject to your compliance with these Terms of Service, Shipfro grants you a limited, non-exclusive, non-transferable, non-sublicensable license to access and make personal and non-commercial use of the Shipfro platform. This license does not include any resale or commercial use of any Shipfro service or its content, any collection and use of any product listings, descriptions, or prices, any derivative use of the Shipfro platform or its content, any downloading or copying of account information for the benefit of another merchant, or any use of data mining, robots, or similar data gathering and extraction tools..",
      },
    ],
  },
  {
    heading: "Agreement with Courier Partners",
    description:
      "Subject to your compliance with these Terms of Service, Shipfro grants you a limited, non-exclusive, non-transferable, non-sublicensable license to access and make personal and non-commercial use of the Shipfro platform. This license does not include any resale or commercial use of any Shipfro service or its content, any collection and use of any product listings, descriptions, or prices, any derivative use of the Shipfro platform or its content, any downloading or copying of account information for the benefit of another merchant, or any use of data mining, robots, or similar data gathering and extraction tools..",
  },
  {
    heading: "Termination",
    description:
      "You have the right to terminate your account at any time by sending a cancellation request to support@shipfro.com. Subject to earlier termination as provided below, Shipfro may terminate your account and these Terms of Service at any time by providing thirty (30) days' prior notice to the email address associated with your account. In addition to any other remedies we may have, Shipfro may also terminate these Terms of Service upon thirty (30) days' notice (or ten (10) days in the case of non-payment), if you breach any of the Terms of Service or conditions herein. Also, Shipfro reserves the right to modify or discontinue, temporarily or permanently, the services (or any part thereof). Except as provided above, upon any termination of your account, Shipfro may store all of your content on the services, or it may be permanently deleted by Shipfro, in its sole discretion. Shipfro will reimburse the balance within ninety (90) days.",
  },
  {
    heading: "Disputes",
    description:
      "While disagreements sometimes arise, Shipfro wants to address any complaint with the services in a proactive manner. Please log a support case for resolution or contact us at support@shipfro.com. If you feel an escalation is merited, please email support@shipfro.com regarding your unresolved case and your concern",
  },
  {
    heading: "Governing Law",
    description:
      "These Terms of Service shall be governed by the laws of Pakistan. By using our services, you agree to submit to the exclusive jurisdiction of the Pakistan Courts. Shipfro shall have the right to collect from you its reasonable costs and necessary disbursements and attorney's fees incurred in enforcing these Terms of Service.",
  },
  {
    heading: "General Provisions",
    subHeadings: [
      {
        heading: "Severability",
        description:
          "If any provision of these Terms of Service is held invalid or unenforceable by a court of competent jurisdiction, such provision shall be modified to the extent necessary to make it valid and enforceable, and the remaining provisions of these Terms of Service shall remain in effect and enforceable.",
      },
      {
        heading: "No Waiver",
        description:
          "Failure or delay of Shipfro to exercise a right or power under these Terms of Service shall not operate as a waiver thereof, nor shall any single or partial exercise of a right or power preclude any other future exercise thereof.",
      },
      {
        heading: "Notices to You",
        description:
          "You agree that Shipfro may provide notice to you by posting it on our website, emailing it to the email address listed in your account, showing a notification when you log in to your account, or mailing it to the street address listed in your account. Such notice shall be considered to be received, read, and understood by you within 24 hours of the time it is posted to our website or emailed to the email address listed in your account. If the notice is sent by mail, we will consider it to have been read and understood by you within 24 hours of the time it is delivered. Shipfro reserves the right to terminate your account if you withdraw your consent to receive electronic communications.",
      },
      {
        heading: "Assignment",
        description:
          "You may not transfer or assign any rights or obligations you have under this agreement without Shipfro's prior written consent, and any attempted assignment without that consent will be void. Shipfro reserves the right to transfer or assign this agreement or any right or obligation under this agreement at any time",
      },
      {
        heading: "Force Majeure",
        description:
          "Shipfro shall not be liable for any delays or inability to perform the services caused by forces beyond Shipfro's control, including acts of God, acts of third-party service providers, war, terrorism, public enemies, seizure under legal process, strikes, lockouts, riots and civil commotions, or any reason beyond Shipfro's control.",
      },
    ],
  },
];
export default TermsOfService;
