import { createContext } from "react";
import useIdentity from "@ourodemi/identity-react";

const APIContext = createContext(null);

export const APIProvider = ({ children }) => {

    const { api, isAuthenticated } = useIdentity();

    const getAllAddresses = async () => {
        return api('/addresses/?limit=50')
        .then((res) => {
            return res?.data?.addresses;
        })
        .catch((error) => {
            throw error?.response?.data?.error;
        });
    }

    const addNewAdressToAddressBook = async (addressToAdd) => {
        return api('/addresses', {
            method: 'POST',
            data: addressToAdd
        })
        .then((res) => {
            return res?.data?.address;
        })
        .then((address) => {
            return address;
        })
        .catch((error) => {
            throw error?.response?.data?.error;
        });
    }

    const updateAddressInAddressBook = async (addressToUpdate,address_id) => {
        return api(`/addresses/${address_id}`, {
            method: 'PATCH',
            data: addressToUpdate
        })
        .then((res) => {
            return res?.data?.address;
        })
        .then((address) => {
            return address;
        })
        .catch((error) => {
            console.log(error);
            throw error?.response?.data?.error;
        });
    }

    const deleteAddressFromAddressBook = async (address_id) => {
        return api(`/addresses/${address_id}`, {
            method: 'DELETE'
        })
        .then((res) => {
            return res?.data?.address;
        })
        .then((address) => {
            return address;
        })
        .catch((error) => {
            throw error?.response?.data?.error;
        });
    }


    const createBooking = async ({
        courier_id, packages, shipping_service,
        payment_method, origin, destination
    }) => {
        return api(`/bookings`, {
            method: 'POST',
            useAuth: true,
            data: {
                courier_id, shipping_service,
                payment_method, origin, destination,
                packages: packages.map(({description, ...v}, _) => {
                    return {...v, description: description || "N/A"}
                })
            }
        })
        .then(res => res?.data?.booking)
        .then(booking => {
            if ( !booking ){
                throw "Unable to complete booking. Please contact support!";
            }

            return booking;
        })
    }

    const getQuote = async ({
        to_city, to_state, 
        from_city, from_state,
        packages, shipping_service,
        courier
    }) => {
        const baseParams = {
            from_city, from_state,
            to_city, to_state,
            weight: packages[0].weight,
            packaging: packages[0].packaging,
            dimensions: [packages[0].length, packages[0].width, packages[0].height].join(","),
        };
    
        const additionalParams = {
            ...(shipping_service !== undefined && { shipping_service }),
            ...(courier !== undefined && { courier })
        };
    
        const params = new URLSearchParams({
            ...baseParams,
            ...additionalParams
        });

        return api(`/quotes?${params.toString()}`, {
            useAuth: isAuthenticated ? true : false // useAuth if available
        })
        .then(res => {
            if ( !res?.data?.quotes || !Array.isArray(res.data.quotes) ){
                throw "Unable to get quotes from the API";
            }

            return res.data.quotes;
        });
    }

    return (
      <APIContext.Provider
        value={{
          createBooking,
          getQuote,
          isAuthenticated,
          deleteAddressFromAddressBook,
          addNewAdressToAddressBook,
          updateAddressInAddressBook,
          getAllAddresses,
        }}
      >
        {children}
      </APIContext.Provider>
    );
}

export default APIContext;