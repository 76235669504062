import React from 'react';
import "./AccountStatus.css";

const AccountStatus = (props) => {
  let backgroundColor = ""; // Initialize background color variable

  // Conditionally set the background color based on props.status
  switch (props.status) {
    case "Approved":
      backgroundColor = "green";
      break;
    case "Pending":
      backgroundColor = "goldenrod";
      break;
    case "Rejected":
      backgroundColor = "red";
      break;
    default:
      backgroundColor = "";
  }

  return (
    <div className="status-tag" style={{ backgroundColor }}>
      <span style={{color:"white"}}>{props.status}</span>
    </div>
  );
};

export default AccountStatus;